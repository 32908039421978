/* Stolen & Modified version of atlas-ui select that adds a search box */

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgencyTag } from 'src/app/models/agency-tag';
import { CurrentSelection } from 'src/app/models/current-selection';
import { AgencyTagService } from 'src/app/services/agencytag.service';
import { FilterService } from 'src/app/services/filter.service';
import { AtlasSelectOption } from '@wellsky/atlas-ui/select/select.model';

@Component({
    selector: 'app-filter-agency-tag',
    templateUrl: './filter-agency-tag.component.html',
    styleUrls:['./filter-agency-tag.component.scss']
})
export class FilterAgencyTagComponent implements OnInit{
    agencyTagData: AtlasSelectOption[];
    defaultOption: string;
    agencyTagFilterFormControl = new FormControl([]);

    constructor(private agencyTagService: AgencyTagService,
        private filterService: FilterService){}

    ngOnInit(): void {
        this.defaultOption = 'Profile Tag';
        this.toggleEnabledDisabled();
        this.getAgencyTagList();
        this.subscribeToFilterSelectionChanges();
    }

    updateAgencyTagFilter(): void {
        const selectedValues = this.agencyTagData
        .filter(atd => this.agencyTagFilterFormControl.value.findIndex(v => v === atd.value) > -1)
        .map(v => {
            if (Array.isArray(v.value)) {
                throw new Error('Agency Tag Filter Component can only use AtlasSelectOption as AtlasSelectDataSource')
            }
            return new CurrentSelection(v.value, v.label);
        });
        this.filterService.addMultiValuedFilter('agencyTagId', selectedValues);
    }

    private getAgencyTagList(): void {
        this.agencyTagService.getAgencyTagList().pipe()
        .subscribe((agencyTags: AgencyTag[]) => {
            this.agencyTagData = this.mapAgencyTagsToAtlasSelect(agencyTags);
            this.toggleEnabledDisabled();
        });
    }

    private mapAgencyTagsToAtlasSelect(agencyTags: AgencyTag[]): AtlasSelectOption[] {
        if (!agencyTags) return [];

        const result = agencyTags.map(at =>({
            label: at.name,
            value: at.agencyTagId.toString()
        }))
        return result;
    }

    private toggleEnabledDisabled() {
        if (this.agencyTagData && this.agencyTagData.length > 0){
            this.agencyTagFilterFormControl.enable();
        }
        else{
            this.agencyTagFilterFormControl.disable();
        }
    }

    private subscribeToFilterSelectionChanges() : void{
        this.filterService.currentSelectionsChange$.subscribe(() => {
            const currentAgencyTagIdSelections = this.filterService.currentSelections.agencyTagId;
            if (!currentAgencyTagIdSelections){
                this.agencyTagFilterFormControl.reset([]);
                return;
            }
            this.agencyTagFilterFormControl.patchValue(currentAgencyTagIdSelections.map(cs => cs.filterValue));
        });
    }
};
