<ng-container *ngIf="(shiftsCancelledService.series$ | async) as series">
  <div id="shifts-cancelled-chart" data-cy="shifts-cancelled-chart" *ngIf="series.length > 0 && series[0].data.length > 0">
    <apx-chart
      [series]="series"
      [chart]="settings.chart"
      [xaxis]="settings.xAxis"
      [stroke]="settings.stroke"
      [tooltip]="settings.toolTip"
      [dataLabels]="settings.dataLabels"
      [legend]="settings.legend"
      [markers]="settings.markers"
      [grid]="settings.grid"
      [yaxis]="settings.yAxis"
      [colors]="settings.colors"
    ></apx-chart>
  </div>
  <atlas-spinner *ngIf="shiftsCancelledService.loading$ | async"></atlas-spinner>
</ng-container>
