<atlas-select
  [formControl]="formControl"
  [dataSource]="turnoverRiskOptions"
  [multiple]="true"
  [fieldAppearance]="'fill'"
  [label]="'Turnover Risk'"
  (selectionChange)="updateTurnoverRiskFilter()"
  data-cy="turnover-risk-filter"
>
</atlas-select>

