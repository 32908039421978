<app-caregiver-performance-grid-options-header 
    [allowExportToCsv]="allowExportToCsv"
    [sort]="sort">
</app-caregiver-performance-grid-options-header>
<app-caregiver-performance-grid-search></app-caregiver-performance-grid-search>
<!--<atlas-tab-group>-->
<!--  <atlas-tab [label]="allCaregiverTabLabel">-->
<app-caregiver-performance-grid 
    (allowExportToCsv)="allowExportToCsv = $event"
    (sortChanged)="sort = $event">
</app-caregiver-performance-grid>
<!--  </atlas-tab>-->
<!--</atlas-tab-group>-->
