import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AtlasCardModule } from '@wellsky/atlas-ui/card';
import { AtlasTopNavModule } from '@wellsky/atlas-ui/topnav';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorProviders } from './interceptors';
import { NavbarComponent } from './navbar/navbar.component';
import { MatIconModule } from '@angular/material/icon';
import { AtlasButtonModule } from '@wellsky/atlas-ui/button';
import { AtlasMenuModule } from '@wellsky/atlas-ui/menu';
import { MatMenuModule } from '@angular/material/menu';
import { AtlasBannersModule, AtlasSpinnerModule, AtlasTabsModule, } from '@wellsky/atlas-ui';
import {
  CaregiverManagementDashboardModule
} from './caregiver-management-dashboard/caregiver-management-dashboard.module';
import { TeamEngageDashboardModule } from './team-engage-dashboard/team-engage-dashboard.module';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    AtlasTopNavModule,
    AtlasCardModule,
    AtlasButtonModule,
    AtlasMenuModule,
    NgbModule,
    MatIconModule,
    MatMenuModule,
    AtlasBannersModule,
    AtlasSpinnerModule,
    AtlasTabsModule,
    CaregiverManagementDashboardModule,
    TeamEngageDashboardModule
  ],
  providers: [HttpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {
}
