import { NgModule } from '@angular/core';
import { AtlasCardModule } from '@wellsky/atlas-ui/card';
import { AtlasButtonModule } from '@wellsky/atlas-ui/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
  AtlasCheckboxModule,
  AtlasChipsModule,
  AtlasSearchModule,
  AtlasSelectModule,
  AtlasSpinnerModule,
  AtlasTabsModule,
  AtlasTooltipModule,
} from '@wellsky/atlas-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { TenureDisplayComponent } from './grid/tenure-display/tenure-display.component';
import { CoreModule } from '../core/core.module';
import { CaregiverManagementDashboardComponent } from './caregiver-management-dashboard.component';
import { CaregiverManagementDashboardRoutingModule } from './caregiver-management-dashboard-routing.module';
import {
  CaregiverPerformanceGridComponent
} from './grid/caregiver-performance-grid/caregiver-performance-grid.component';
import {
  CaregiverManagementDashboardTitleComponent
} from './caregiver-management-dashboard-title/caregiver-management-dashboard-title.component';
import {
  FilterDateSelectionDisplayComponent
} from './filters/filter-date-selection-display/filter-date-selection-display.component';
import { FilterDrawerToggleComponent } from './filters/filter-drawer-toggle/filter-drawer-toggle.component';
import {
  CaregiverManagementDashboardChartContainerComponent
} from './charts/caregiver-management-dashboard-chart-container/caregiver-management-dashboard-chart-container.component';
import {
  CaregiverPerformanceGridContainerComponent
} from './grid/caregiver-performance-grid-container/caregiver-performance-grid-container.component';
import { FilterDrawerComponent } from './filters/filter-drawer/filter-drawer.component';
import {
  CaregiverPerformanceGridOptionsHeaderComponent
} from './grid/caregiver-performance-grid-options-header/caregiver-performance-grid-options-header.component';
import {
  CaregiverPerformanceGridSearchComponent
} from './grid/caregiver-performance-grid-search/caregiver-performance-grid-search.component';
import { AvgDailyMileageDisplayComponent } from './grid/avgdailymileage-display/avgdailymileage-display.component';
import { CanceledShiftsComponent } from './grid/canceled-shifts/canceled-shifts.component';
import { TardinessDisplayComponent } from './grid/tardiness-display/tardiness-display.component';
import { UtilizationDisplayComponent } from './grid/utilization-display/utilization-display.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CaregiverNameDisplayComponent } from './grid/caregiver-name-display/caregiver-name-display.component';
import { MatButtonModule } from '@angular/material/button';
import {
  CaregiverPerformanceGridEmptyStateComponent
} from './grid/caregiver-performance-grid-empty-state/caregiver-performance-grid-empty-state.component';
import { FilterDrawerHeaderComponent } from './filters/filter-drawer-header/filter-drawer-header.component';
import { FilterClearAllButtonComponent } from './filters/filter-clear-all-button/filter-clear-all-button.component';
import { FilterLocationComponent } from './filters/filter-location/filter-location.component';
import {
  FilterCurrentSelectionsDisplayComponent
} from './filters/filter-current-selections-display/filter-current-selections-display.component';
import {
  FilterToggleCurrentSelectionsContainerComponent
} from './filters/filter-toggle-current-selections-container/filter-toggle-current-selections-container.component';
import { FilterChipOverflowComponent } from './filters/filter-chip-overflow/filter-chip-overflow.component';
import { HighHospRiskComponent } from './grid/high-hosp-risk/high-hosp-risk.component';
import { TurnoverRiskComponent } from './grid/turnover-risk/turnover-risk.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TardinessChartComponent } from './charts/tardiness-chart/tardiness-chart.component';
import { ChartCardComponent } from './charts/chart-card/chart-card.component';
import { TardinessKpiComponent } from './charts/tardiness-kpi/tardiness-kpi.component';
import { ShiftsCancelledChartComponent } from './charts/shifts-cancelled-chart/shifts-cancelled-chart.component';
import { ShiftsCancelledKpiComponent } from './charts/shifts-cancelled-kpi/shifts-cancelled-kpi.component';
import { FilterDatesComponent } from './filters/filter-dates/filter-dates.component';
import { ManageColumnsDropdownComponent } from './grid/manage-columns-dropdown/manage-columns-dropdown.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { TurnoverChartComponent } from './charts/turnover-chart/turnover-chart.component';
import { TurnoverKpiComponent } from './charts/turnover-kpi/turnover-kpi.component';
import { FilterAgencyTagComponent } from './filters/filter-agency-tag/filter-agency-tag.component';
import { MultiSelectSearchComponent } from './filters/multi-select-search/multi-select-search.component';
import { CarouselModule } from './carousel/carousel.module';
import { TurnoverRiskChartComponent } from './charts/turnover-risk-chart/turnover-risk-chart.component';
import {SatPopoverModule} from "@ncstate/sat-popover";
import {
  TurnoverRiskFactorsPopoverComponent
} from "./grid/caregiver-performance-grid/turnover-risk-factors-popover/turnover-risk-factors-popover.component";
import { PointsEarnedRedeemedChartComponent } from './charts/points-earned-redeemed-chart/points-earned-redeemed-chart.component';
import { PointsEarnedRedeemedKpiComponent } from './charts/points-earned-redeemed-kpi/points-earned-redeemed-kpi.component';
import {ReferralsSubmittedChartComponent} from "./charts/referrals-submitted-chart/referrals-submitted-chart.component";
import {ReferralsSubmittedKpiComponent} from "./charts/referrals-submitted-kpi/referrals-submitted-kpi.component";
import {
  GoalCompletionRateChartComponent
} from "./charts/goal-completion-rate-chart/goal-completion-rate-chart.component";
import {GoalCompletionRateKpiComponent} from "./charts/goal-completion-rate-kpi/goal-completion-rate-kpi.component";
import {FilterTurnoverRiskComponent} from "./filters/filter-turnover-risk/filter-turnover-risk.component";

@NgModule({
  declarations: [
    CaregiverManagementDashboardComponent,
    TenureDisplayComponent,
    AvgDailyMileageDisplayComponent,
    CaregiverPerformanceGridComponent,
    CaregiverManagementDashboardTitleComponent,
    FilterDateSelectionDisplayComponent,
    FilterDrawerToggleComponent,
    CaregiverManagementDashboardChartContainerComponent,
    CaregiverPerformanceGridContainerComponent,
    FilterDrawerComponent,
    CaregiverPerformanceGridOptionsHeaderComponent,
    CaregiverPerformanceGridSearchComponent,
    CanceledShiftsComponent,
    TardinessDisplayComponent,
    UtilizationDisplayComponent,
    CaregiverNameDisplayComponent,
    CaregiverPerformanceGridEmptyStateComponent,
    FilterDrawerHeaderComponent,
    FilterClearAllButtonComponent,
    FilterLocationComponent,
    FilterCurrentSelectionsDisplayComponent,
    FilterToggleCurrentSelectionsContainerComponent,
    FilterChipOverflowComponent,
    HighHospRiskComponent,
    TurnoverRiskComponent,
    TardinessChartComponent,
    ChartCardComponent,
    TardinessKpiComponent,
    ShiftsCancelledChartComponent,
    ShiftsCancelledKpiComponent,
    FilterDatesComponent,
    ManageColumnsDropdownComponent,
    TurnoverChartComponent,
    TurnoverKpiComponent,
    FilterAgencyTagComponent,
    MultiSelectSearchComponent,
    TurnoverRiskChartComponent,
    TurnoverRiskFactorsPopoverComponent,
    PointsEarnedRedeemedChartComponent,
    PointsEarnedRedeemedKpiComponent,
    ReferralsSubmittedChartComponent,
    ReferralsSubmittedKpiComponent,
    GoalCompletionRateChartComponent,
    GoalCompletionRateKpiComponent,
    FilterTurnoverRiskComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    CaregiverManagementDashboardRoutingModule,
    MatButtonModule,
    AtlasCardModule,
    AtlasButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AtlasSpinnerModule,
    AtlasTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AtlasChipsModule,
    MatMenuModule,
    AtlasTabsModule,
    AtlasSearchModule,
    AtlasSelectModule,
    NgApexchartsModule,
    MatDatepickerModule ,
    MatNativeDateModule,
    MatMomentDateModule,
    AtlasCheckboxModule,
    DragDropModule,
    CdkScrollableModule,
    CarouselModule,
    SatPopoverModule
  ],
  providers: [TitleCasePipe],
  exports: [],
})
export class CaregiverManagementDashboardModule {
}
