<atlas-chip-list class="chip-list-fixed-height"
  aria-label="Current Filter Selections">
  <atlas-chip
    *ngFor="let currentSelection of currentSelectionsDisplay"
    [selectable]="false"
    [removable]="true"
    (removed)="filterService.removeFilterSelectionByDisplay(currentSelection)">
    {{currentSelection.filterKeyLabel}}: {{currentSelection.filterValueLabel}}
    <mat-icon atlasChipRemove [attr.data-cy]="'chip-remove-button-' + currentSelection.filterKey + '-' + currentSelection.filterValue">cancel</mat-icon>
  </atlas-chip>
</atlas-chip-list>
