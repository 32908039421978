<div class="filter-drawer-toggle-button">
  <button
    atlas-button
    class="text-uppercase"
    color="accent"
    data-cy="filter-drawer-trigger"
    (click)="filterDrawerService.toggle()"
  >
    <mat-icon>filter_list</mat-icon>
    Filter
  </button>
</div>

