<div class="filter-chip-overflow-button" *ngIf="overflowingCurrentSelections?.length > 0" data-cy="chip-overflow-button">
  <button
    mat-button
    class="text-uppercase"
    color="accent"
    data-cy="filter-chip-overflow-button"
    [matMenuTriggerFor]="fullChipList"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>
</div>

<mat-menu #fullChipList="matMenu" xPosition="before">
    <div class="current-selections-menu-header">
      <h4>Current Selections</h4>
    </div>
    <div class="current-selections-menu-body">
      <atlas-chip-list class="chip-list-fixed-height"
                       aria-label="Current Filter Selections">
        <atlas-chip
          *ngFor="let currentSelection of overflowingCurrentSelections"
          [selectable]="false"
          [removable]="true"
          (removed)="filterService.removeFilterSelectionByDisplay(currentSelection)">
          {{currentSelection.filterKeyLabel}}: {{currentSelection.filterValueLabel}}
          <mat-icon atlasChipRemove [attr.data-cy]="'chip-overflow-remove-button-' + currentSelection.filterKey + '-' + currentSelection.filterValue">cancel</mat-icon>
        </atlas-chip>
      </atlas-chip-list>
    </div>
    <div class="current-selections-menu-footer">
      <button mat-stroked-button color="accent" data-cy="filter-overflow-clear-filters-button"
              (click)="filterService.clearFilterBarFilters()">
        CLEAR ALL FILTERS
      </button>
    </div>
</mat-menu>
