<ng-container *ngIf="(turnoverRiskService.series$ | async) as series">
  <div id="turnover-risk-chart"
       data-cy="turnover-risk-chart"
       *ngIf="settings && series[0]?.data.length > 0"
  >
    <apx-chart #turnoverRiskChart
      [series]="series"
      [chart]="settings.chart"
      [xaxis]="settings.xAxis"
      [tooltip]="settings.toolTip"
      [dataLabels]="settings.dataLabels"
      [markers]="settings.markers"
      [grid]="settings.grid"
      [yaxis]="settings.yAxis"
      [colors]="settings.colors"
      [plotOptions]="settings.plotOptions"
      [states]="settings.states"
      [legend]="{show: false}"
    ></apx-chart>
  </div>
  <atlas-spinner *ngIf="turnoverRiskService.loading$ | async"></atlas-spinner>
</ng-container>
