import { Component, OnInit } from '@angular/core';
import { AtlasSelectDataSource } from '@wellsky/atlas-ui';
import { FormControl } from '@angular/forms';
import { FilterService } from '../../../services/filter.service';
import { CurrentSelection } from '../../../models/current-selection';
import { LocationService } from 'src/app/services/location.service';
import { ProfileLocation } from 'src/app/models/profile-location';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'app-filter-location',
  templateUrl: './filter-location.component.html',
  styleUrls: ['./filter-location.component.scss']
})
export class FilterLocationComponent implements OnInit {
  locationData: AtlasSelectDataSource[];
  defaultOption: string;
  locationFilterFormControl = new FormControl([]);

  constructor(private filterService: FilterService,
    private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.defaultOption = 'Location';
    this.toggleEnabledDisabled();
    this.getUserLocations();
    this.subscribeToFilterSelectionChanges();
  }

  updateLocationFilter(): void {
    const selectedValues = this.locationData
      .filter(ld => this.locationFilterFormControl.value.findIndex(sv => sv === ld.value) > -1)
      .map(sv => {
        if (Array.isArray(sv.value)) {
          throw new Error('Filter Location Component can only use AtlasSelectOption as AtlasSelectDataSource');
        }
        return new CurrentSelection(sv.value, sv.label);
      });
    this.filterService.addMultiValuedFilter('locationId', selectedValues);
  }

  private getUserLocations(): void {
    this.locationService.getProfileLocationList().pipe(
      switchMap((locations: ProfileLocation[]) =>{
        if (locations === undefined || locations.length === 0){
          return this.locationService.getLocationList();
        } else {
          return of(locations);
        }
      })
    ).subscribe((locations: ProfileLocation[]) =>{
      this.locationData = this.mapUserLocationToAtlasSelect(locations);
      this.toggleEnabledDisabled();
    });
  }

  private mapUserLocationToAtlasSelect(locations: ProfileLocation[]): AtlasSelectDataSource[] {
    if (!locations) return [];

    const result = locations.map(l => ({
      label: l.locationName,
      value: l.locationId.toString(),
      disabled: (l.showCareGivers === undefined) ? false : !l.showCareGivers
    }))
    return result;
  }

  private subscribeToFilterSelectionChanges(): void {
    this.filterService.currentSelectionsChange$.subscribe(() => {
      const currentLocationIdSelections = this.filterService.currentSelections.locationId;
      if (!currentLocationIdSelections) {
        this.locationFilterFormControl.reset([]);
        return;
      }
      this.locationFilterFormControl.patchValue(currentLocationIdSelections.map(cs => cs.filterValue));
    });
  }

  private toggleEnabledDisabled(): void {
    if (this.locationData && this.locationData.length > 0) {
      this.locationFilterFormControl.enable();
    } else {
      this.locationFilterFormControl.disable();
    }
  }
}
