import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors, } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance, } from '@angular/material/form-field';
import { ThemePalette } from '@angular/material/core';
import { AtlasFormFieldErrors } from '@wellsky/atlas-ui/core';
import { MatSelect } from '@angular/material/select';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { AtlasSelectDataSource } from '@wellsky/atlas-ui';
import { AtlasSelectOption } from '@wellsky/atlas-ui/select/select.model';

// Global counter variable for assigning dynamic id
let nextUniqueId = 0;

@Component({
  selector: 'app-multi-select-search',
  templateUrl: './multi-select-search.component.html',
  styleUrls: ['./multi-select-search.component.scss'],
  exportAs: 'appMultiSelectSearch',
})
export class MultiSelectSearchComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, AfterViewChecked {
  private _errors: AtlasFormFieldErrors;
  private _selectControlSubscription: Subscription;
  private _multiple: boolean;
  private _value: string;

  disableOptionCentering: boolean;
  selectControl: FormControl;
  errorMessage: string;
  searchTerm: string = '';
  @ViewChild(MatSelect, {read: ElementRef}) private matSelect: ElementRef<HTMLElement>;

  // Material Select---------------
  @Input('aria-label')
  ariaLabel: string;

  @Input('aria-labelledby')
  ariaLabelledby: string;

  @Input()
  disableRipple: boolean;

  @Input()
  id: string;

  @Input()
  set multiple(multiple: boolean | '') {
    this._multiple = multiple === '' || multiple;
  }
  get multiple(): boolean | '' {
    return this._multiple;
  }

  @Input()
  placeholder: string;

  @Input()
  required: boolean;

  @Input()
  value: any;

  @Output()
  openedChange: EventEmitter<boolean>;

  @Output()
  selectionChange = new EventEmitter<object>();

  // Material FormField-----------------
  @Input()
  fieldAppearance: MatFormFieldAppearance;

  @Input()
  floatLabel: FloatLabelType;

  @Input()
  hideRequiredMarker: boolean;

  @Input()
  label: string;

  // Enables default option to clear the choice
  @Input()
  default: string;

  // Data source for select
  @Input() dataSource: AtlasSelectOption[];
  // Get error set
  @Input()
  get errors(): AtlasFormFieldErrors { return this._errors; }
  set errors(errors: AtlasFormFieldErrors) {
    if (errors && errors instanceof AtlasFormFieldErrors) {
      this._errors = errors;
    } else {
      this._errors = new AtlasFormFieldErrors();
    }
  }

  /**
   * Input of input component: color
   * Supports three values: 'primary' | 'accent' | 'warn' | undefined
   */
  @Input()
  color: ThemePalette;

  // tab index for select
  @Input()
  tabIndex: number;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private renderer2: Renderer2,
    private overlay: OverlayContainer,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {

    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    // Select FromControl
    this.selectControl = new FormControl();

    // Errors
    this.errors = new AtlasFormFieldErrors();

    // Emit panel toggled
    this.openedChange = new EventEmitter<boolean>();

    // Values: 'legacy' | 'standard' | 'fill' | 'outline'
    this.fieldAppearance = 'fill';

    // Values: 'auto' | 'always' | 'never'
    this.floatLabel = 'auto';

    // false: for hiding the required marker when required is enabled
    this.hideRequiredMarker = false;

    // Set default color to 'accent'
    this.color = 'accent';

    // Set disableOptionCentering to true
    this.disableOptionCentering = true;

    // setting default id of select when not passed by user
    this.id = `atlas-select-${nextUniqueId++}`;

    // By Default the tab index for the select is 0
    this.tabIndex = 0;
  }

  // Triggers select control's value change
  private triggerControlValueChange() {
    if (this._selectControlSubscription) {
      this._selectControlSubscription.unsubscribe();
    }

    this._selectControlSubscription = this.selectControl.valueChanges.subscribe(
      (options) => {
        // Emit value to CVA
        this.onChange(options);
      }
    );
  }

  ngOnInit() {
    // Triggers select control's value change
    this.triggerControlValueChange();

    // Get and update the errors on selectControl
    if (this.ngControl != null) {
      this.ngControl.valueChanges.subscribe(
        () => {
          // Set errors
          if (this.ngControl.invalid && this.ngControl.touched) {
            this.selectControl.setErrors(this.ngControl.errors);
          } else {
            this.selectControl.setErrors(null);
          }

          // Update errors
          this.updateError(this.ngControl.invalid, this.ngControl.errors, this.errors);
        }
      );
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // On multiple option change
    if (changes.multiple) {
      this.selectControl = new FormControl({
        value: this._value,
        disabled: this.selectControl.disabled,
      });

      //Triggers select control's value change
      this.triggerControlValueChange();
    }
  }

  public shouldShow(element: AtlasSelectOption): boolean {
    if (!this.searchTerm) {
      return true;
    }
    const searchValue = this.searchTerm.trim().toLowerCase();
    return element.label.toLowerCase().includes(searchValue);
  }

  public fireSelectionChange(event) {
    this.selectionChange.emit(event);
  }

  // Gets the tab index value and sets it to select control
  public getTabIndex() {
    if (this.selectControl.disabled) {
      return -1;
    } else {
      return this.tabIndex || 0;
    }
  }

  // Updates error message according to the ValidationErrors
  public updateError(isInputInvalid: boolean, activeErrors: ValidationErrors, errorSet: AtlasFormFieldErrors): void {
    // Update error message
    if (isInputInvalid && activeErrors) {
      this.errorMessage = this.getErrorMessage(activeErrors, errorSet);
    } else {
      this.errorMessage = '';
    }
  }

  // get error message
  public getErrorMessage(activatedErrors: ValidationErrors, atlasErrors: AtlasFormFieldErrors) {
    let errorMessage = 'Wrong Input';
    const _activatedErrors = Object.keys(activatedErrors);
    const errors: Map<string, string> = atlasErrors.getErrors();

    // Find valid error and assign its message
    for (let errorCode = 0; errorCode < _activatedErrors.length; errorCode++) {
      if (errors.has(_activatedErrors[errorCode])) {
        errorMessage = atlasErrors.getErrorMessage(_activatedErrors[errorCode]);
        break;
      }
    }
    return errorMessage;
  }

  // Is item group or option
  public isItemGroup(item: AtlasSelectDataSource): 'group' | 'option' {
    if (item && Array.isArray(item.value)) {
      return 'group';
    } else if (item && typeof item.value === 'string') {
      return 'option';
    } else {
      return null;
    }
  }

  private getFont() {
    const fontValue = getComputedStyle(this.matSelect.nativeElement).getPropertyValue('font-size').split('px');
    return `${parseInt(fontValue[0], 10) * 2}px`;
  }

  /**
   * To open the overlay panel from bottom of the select control when isPanelToggled
   * @param isPanelToggled
   * @Output() openedChange($event)
   */
  public isPanelToggled(isPanelToggled: boolean): void {
    const overlay = this.overlay.getContainerElement();

    if (isPanelToggled && overlay) {
      //  Compute margin-top based on the font-size of parent element
      const panel = overlay.querySelector('.mat-select-panel-wrap').parentElement as HTMLElement;
      this.renderer2.setStyle(panel, 'margin-top', this.getFont());
    }
    this.openedChange.emit(isPanelToggled);
  }

  // -----------ControlValueAccessor-----------------
  // Function to call when the change detects.
  private onChange = (input) => { };
  // Function to call when the input is touched.
  private onTouched = () => { };
  // Allows Angular to update the model.
  writeValue(input: any): void {
    this._value = input;
    // Update the model and changes needed for the view here.
    this.selectControl.setValue(this._value, {emitEvent: false});
  }

  // Allows Angular to register a function to call when the model changes.
  registerOnChange(fn: (input: any) => void): void {
    // Save the function as a property to call later here.
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  registerOnTouched(fn: any): void {
    // Save the function as a property to call later here.
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Disables the selectControl
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  ngOnDestroy() {
    if (this._selectControlSubscription) {
      this._selectControlSubscription.unsubscribe();
    }
  }
}
