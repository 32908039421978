<atlas-select
  [formControl]="locationFilterFormControl"
  [default]="defaultOption"
  [dataSource]="locationData"
  [multiple]="true"
  [fieldAppearance]="'fill'"
  [label]="'Location'"
  (selectionChange)="updateLocationFilter()"
  data-cy="location-filter"
>
</atlas-select>
